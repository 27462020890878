<template>
  <mobile-screen :header="true" screen-class="edit-level gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper
        menu-class="icon-hea1"
        :helpOnline="
          Boolean(
            helpOnline[helpSlug] &&
              helpOnline[helpSlug].name &&
              helpOnline[helpSlug].content
          )
        "
        :helpOnlineRoute="{
          name: 'r_levels-edit-help-online',
          params: $route.params
        }"
      >
        <template v-slot:left>
          <router-link
            :to="{
              name: 'r_levels',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("level", "edit-level", "edit-level") }}
        </div>
        <template v-slot:right>
          <button
            v-if="selectedLevel && canItem(selectedLevel[0], 'update')"
            :class="{ 'global-hidden': mode === 'edit' }"
            @click="setMode('edit')"
          >
            <icon icon="#cx-hea1-edit" v-if="$route.name == 'r_edit-level'" />
          </button>
          <button
            v-if="$route.name == 'r_edit-level'"
            :class="{ 'global-hidden': mode === 'display' }"
            @click="initSubmit"
          >
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      :key="formKey"
      v-if="level && level.data"
      @submit="submitForm"
      ref="editLevelForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="{
        ...level.data,
        has_translations: hasTranslationsClause
      }"
      novalidate
    >
      <template v-if="selectedLevel && selectedLevel[0]">
        <ul class="clebex-item-section">
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.has_translations }"
          >
            <div class="error-message" v-if="errors.has_translations">
              {{ errors.has_translations }}
            </div>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <div class="clebex-section-input-label">
                  {{ displayLabelName("resources", "edit-level", "name") }}
                </div>
                <p class="text-label">
                  {{ displayLevelSavedName || displayLevelName }}
                </p>
              </div>
              <span class="follow-up-icons" v-if="mode === 'edit'">
                <router-link
                  :to="{
                    name: 'r_edit-level-translate-name',
                    params: $route.params
                  }"
                  class="follow-up-icon-item"
                >
                  <icon icon="#cx-men1-translations" />
                </router-link>
              </span>
            </div>
            <Field
              name="has_translations"
              as="input"
              type="hidden"
              rules="required"
              :value="hasTranslationsClause"
            />
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="phoneLabelId">{{
                  displayLabelName("level", "edit-level", "telephone")
                }}</label>
                <Field
                  :class="{ 'global-hidden': mode === 'display' }"
                  id="phoneLabelId"
                  name="phone"
                  as="input"
                  type="text"
                />
                <p
                  class="text-label"
                  :class="{ 'global-hidden': mode === 'edit' }"
                >
                  {{
                    selectedLevel[0].phone ||
                      displayLabelName("level", "edit-level", "telephone")
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  class="clebex-section-input-label"
                  for="googleMapsLabelId"
                  >{{
                    displayLabelName("level", "edit-level", "google-maps-link")
                  }}</label
                >
                <Field
                  :class="{ 'global-hidden': mode === 'display' }"
                  id="googleMapsLabelId"
                  name="google_maps_link"
                  as="input"
                  type="text"
                />
                <p
                  class="text-label"
                  :class="{ 'global-hidden': mode === 'edit' }"
                >
                  {{
                    selectedLevel[0].google_maps_link ||
                      displayLabelName(
                        "level",
                        "edit-level",
                        "google-maps-link"
                      )
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="address1Id">{{
                  displayLabelName("level", "edit-level", "address1")
                }}</label>
                <Field
                  :class="{ 'global-hidden': mode === 'display' }"
                  id="address1Id"
                  name="address_line_1"
                  as="input"
                  type="text"
                  :disabled="submitting"
                />
                <p
                  class="text-label"
                  :class="{ 'global-hidden': mode === 'edit' }"
                >
                  {{
                    selectedLevel[0].address_line_1 ||
                      displayLabelName("level", "edit-level", "address1")
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="address2Id">{{
                  displayLabelName("level", "edit-level", "address2")
                }}</label>
                <Field
                  :class="{ 'global-hidden': mode === 'display' }"
                  id="address2Id"
                  name="address_line_2"
                  as="input"
                  type="text"
                  :disabled="submitting"
                />
                <p
                  class="text-label"
                  :class="{ 'global-hidden': mode === 'edit' }"
                >
                  {{
                    selectedLevel[0].address_line_2 ||
                      displayLabelName("level", "edit-level", "address2")
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="cityId">{{
                  displayLabelName("level", "edit-level", "city")
                }}</label>
                <Field
                  :class="{ 'global-hidden': mode === 'display' }"
                  id="cityId"
                  name="city"
                  as="input"
                  type="text"
                  :disabled="submitting"
                />
                <p
                  class="text-label"
                  :class="{ 'global-hidden': mode === 'edit' }"
                >
                  {{
                    selectedLevel[0].city ||
                      displayLabelName("level", "edit-level", "city")
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="zipId">{{
                  displayLabelName("level", "edit-level", "zip-code")
                }}</label>
                <Field
                  :class="{ 'global-hidden': mode === 'display' }"
                  id="zipId"
                  name="zip"
                  as="input"
                  type="text"
                  :disabled="submitting"
                />
                <p
                  class="text-label"
                  :class="{ 'global-hidden': mode === 'edit' }"
                >
                  {{
                    selectedLevel[0]["zip"] ||
                      displayLabelName("level", "edit-level", "zip-code")
                  }}
                </p>
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label" for="stateId">{{
                  displayLabelName("level", "edit-level", "state")
                }}</label>
                <Field
                  :class="{ 'global-hidden': mode === 'display' }"
                  id="stateId"
                  name="state"
                  as="input"
                  type="text"
                  :disabled="submitting"
                />
                <p
                  class="text-label"
                  :class="{ 'global-hidden': mode === 'edit' }"
                >
                  {{
                    selectedLevel[0].state ||
                      displayLabelName("level", "edit-level", "state")
                  }}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <ul class="clebex-item-section">
          <!---------------------------------->
          <!-- COUNTRY - START -->
          <!---------------------------------->
          <li class="clebex-item-section-item full-right-underline">
            <div
              @click="
                openCountry(
                  country,
                  'r_edit-level-select-country',
                  $route.params
                )
              "
              v-if="mode === 'edit'"
              style="cursor: pointer;"
              class="clebex-item-content-wrapper"
            >
              <div class="clebex-section-input" style="cursor: pointer;">
                <label
                  class="clebex-section-input-label"
                  style="cursor: pointer;"
                  >{{
                    displayLabelName("level", "edit-level", "country")
                  }}</label
                >
                <p class="text-label" style="cursor: pointer;">
                  {{ country ? country.name : "" }}
                </p>
              </div>
              <span class="follow-up-icons" style="cursor: pointer;">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  />
                </span>
              </span>
            </div>
            <div v-else class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label">{{
                  displayLabelName("level", "edit-level", "country")
                }}</label>
                <p v-if="selectedCountry" class="text-label">
                  {{ selectedCountry.name }}
                </p>
                <p v-else-if="selectedLevel[0].country_id" class="text-label">
                  {{ displayCountryName(selectedLevel[0].country_id) }}
                </p>
                <p v-else class="text-label">
                  {{ displayLabelName("level", "edit-level", "country") }}
                </p>
              </div>
            </div>
            <Field
              name="country_id"
              as="input"
              type="hidden"
              v-model="country"
              :disabled="submitting"
            />
          </li>
          <!---------------------------------->
          <!-- COUNTRY - END -->
          <!---------------------------------->
          <!---------------------------------->
          <!-- TIMEZONE - START -->
          <!---------------------------------->
          <li
            v-if="timezoneUpdateAllowed"
            class="clebex-item-section-item full-right-underline"
          >
            <div
              v-if="mode === 'edit'"
              @click="
                openTimezone(
                  timezone,
                  'r_edit-level-select-timezone',
                  $route.params
                )
              "
              class="clebex-item-content-wrapper"
              style="cursor: pointer;"
            >
              <div class="clebex-section-input" style="cursor: pointer;">
                <label
                  class="clebex-section-input-label"
                  style="cursor: pointer;"
                  >{{
                    displayLabelName("level", "edit-level", "timezone")
                  }}</label
                >
                <p class="text-label ellipsis" style="cursor: pointer;">
                  {{
                    timezone
                      ? timezone.name
                          .replace(/ \*/g, ", ")
                          .replace("UTC ", "UTC+")
                      : ""
                  }}
                </p>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  />
                </span>
              </span>
            </div>
            <div
              v-else
              class="clebex-item-content-wrapper full-right-underline"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label">{{
                  displayLabelName("level", "edit-level", "timezone")
                }}</label>
                <p class="text-label" v-if="inheritedTimezoneId">
                  {{ displayTimezone(inheritedTimezoneId) }}
                </p>
                <p class="text-label" v-else-if="selectedTimezone">
                  {{ displayTimezone(selectedTimezone) }}
                </p>
                <p
                  class="text-label"
                  v-else-if="
                    selectedLevel &&
                      selectedLevel.length &&
                      selectedLevel[0].timezone_id
                  "
                >
                  {{
                    unsetTimezone
                      ? displayLabelName("level", "edit-level", "timezone")
                      : displayTimezone(selectedLevel[0].timezone_id)
                  }}
                </p>
                <p class="text-label" v-else>
                  {{ displayLabelName("level", "edit-level", "timezone") }}
                </p>
              </div>
            </div>
            <Field
              name="timezone_id"
              as="input"
              type="hidden"
              v-model="selectedTimezoneId"
              :disabled="submitting"
            />
          </li>
          <!---------------------------------->
          <!-- TIMEZONE - E D -->
          <!---------------------------------->
          <li class="clebex-item-section-item full-right-underline">
            <router-link
              v-if="mode === 'edit'"
              :to="{
                name: 'r_edit-level-select-working-hours',
                params: $route.params
              }"
              class="clebex-item-content-wrapper cursor-pointer"
            >
              <div class="clebex-section-input">
                <label
                  class="cursor-pointer"
                  :class="
                    workHoursFrom && workHoursTo
                      ? 'clebex-section-input-label'
                      : 'label'
                  "
                  >{{
                    displayLabelName("level", "edit-level", "working-hours")
                  }}</label
                >
                <p v-if="workHoursFrom && workHoursTo" class="text-label">
                  {{ displayTime(workHoursFrom) }} -
                  {{ displayTime(workHoursTo) }}
                </p>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  />
                </span>
              </span>
            </router-link>
            <div
              v-else
              class="clebex-item-content-wrapper full-right-underline"
            >
              <div class="clebex-section-input">
                <label
                  :class="
                    workHoursFrom && workHoursTo
                      ? 'clebex-section-input-label'
                      : 'label'
                  "
                  >{{
                    displayLabelName("level", "edit-level", "working-hours")
                  }}</label
                >
                <p v-if="workHoursFrom && workHoursTo" class="text-label">
                  {{ displayTime(workHoursFrom) }} -
                  {{ displayTime(workHoursTo) }}
                </p>
              </div>
            </div>
            <Field
              name="working_hours_from"
              as="input"
              type="hidden"
              v-model="workingHoursFrom"
              :disabled="submitting"
            />
            <Field
              name="working_hours_to"
              as="input"
              type="hidden"
              v-model="workingHoursTo"
              :disabled="submitting"
            />
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <router-link
              v-if="mode === 'edit'"
              :to="{ name: 'r_edit-level-select-map', params: $route.params }"
              class="clebex-item-content-wrapper"
            >
              <div class="clebex-section-input">
                <label class="clebex-section-input-label"
                  >{{ displayLabelName("level", "edit-level", "5d-maps") }}
                </label>
                <p v-if="selectedMap && !unsetMapValue" class="text-label">
                  {{ selectedMap.name }}
                </p>
                <p
                  v-else-if="selectedLevel[0].map_id && !unsetMapValue"
                  class="text-label"
                >
                  {{ displayMapName(selectedLevel[0].map_id) }}
                </p>
                <p v-else class="text-label">
                  {{ displayLabelName("level", "edit-level", "5d-maps") }}
                </p>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  />
                </span>
              </span>
            </router-link>
            <div v-else class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label class="clebex-section-input-label"
                  >{{ displayLabelName("level", "edit-level", "5d-maps") }}
                </label>
                <p v-if="selectedMap && !unsetMapValue" class="text-label">
                  {{ selectedMap.name }}
                </p>
                <p
                  v-else-if="selectedLevel[0].map_id && !unsetMapValue"
                  class="text-label"
                >
                  {{ displayMapName(selectedLevel[0].map_id) }}
                </p>
                <p v-else class="text-label">
                  {{ displayLabelName("level", "edit-level", "5d-maps") }}
                </p>
              </div>
            </div>
            <Field
              name="map_id"
              as="input"
              type="hidden"
              v-model="mapId"
              :disabled="submitting"
            />
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            v-if="level && level.data && level.data.qrcode"
          >
            <router-link
              :to="
                canItem(level.data, 'update')
                  ? {
                      name: 'r_edit-level-qr-code',
                      params: $route.params
                    }
                  : ''
              "
              class="clebex-item-content-wrapper"
            >
              <div class="label">
                {{ displayLabelName("level", "edit-level", "level-qr-code") }}
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  />
                </span>
              </span>
            </router-link>
          </li>
        </ul>
      </template>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import httpServiceAuth, { getLang } from "@/services/http-service";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import MobileScreen from "@/layouts/MobileScreen";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState, mapGetters } from "vuex";
import { errorHandler } from "@/services/error-handler";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import { DateTime } from "luxon";

import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";

export default {
  name: "EditLevel",
  data() {
    return {
      level: null,
      mode: "edit",
      submitting: false,
      formKey: 0,
      helpSlug: "levels-edit"
    };
  },
  created() {
    this.setSelectedCountry(null);
    this.setSelectedTimezone(null);
    this.selectMap(null);
  },
  mounted() {
    if (!this.selectedLevel) {
      this.$router.push({ name: "r_levels" });
    } else {
      this.getCurrentLevel(this.selectedLevel[0].id);
      this.getMaps({
        includes: ["levels"]
      });
      this.$store.commit(
        "level/setWorkingHoursFrom",
        this.selectedLevel[0].working_hours_from,
        { root: true }
      );
      this.$store.commit(
        "level/setWorkingHoursTo",
        this.selectedLevel[0].working_hours_to,
        { root: true }
      );
    }
  },
  watch: {
    selectedLevel() {
      this.$store.commit("level/selectMap", null, { root: true });
      this.$store.commit("level/setWorkingHoursFrom", null, { root: true });
      this.$store.commit("level/setWorkingHoursTo", null, { root: true });
      this.$store.commit("level/setQrCode", null, {
        root: true
      });
      if (this.formKey === 10) {
        this.formKey = 0;
      } else {
        this.formKey++;
      }
      if (this.selectedLevel && this.selectedLevel.length) {
        this.$refs.editLevelForm.setValues({
          ...this.selectedLevel[0]
        });
        this.getCurrentLevel(this.selectedLevel[0].id);
        this.$store.commit(
          "level/setWorkingHoursFrom",
          this.selectedLevel[0].working_hours_from,
          { root: true }
        );
        this.$store.commit(
          "level/setWorkingHoursTo",
          this.selectedLevel[0].working_hours_to,
          { root: true }
        );
      }
    }
  },
  computed: {
    ...mapState("level", [
      "selectedLevel",
      "levels",
      "selectedMap",
      "maps",
      "levelTranslations",
      "unsetMapValue",
      "workingHoursFrom",
      "workingHoursTo"
    ]),
    ...mapState("language", ["languages"]),
    ...mapState("timezone", ["unsetTimezone"]),
    ...mapGetters("settings", ["globalTimeFormat"]),
    timezone() {
      if (this.selectedTimezone) {
        return this.selectedTimezone;
      } else {
        if (this.level && this.level.data) {
          return this.getTimezone(this.level.data.timezone_id);
        }
        return null;
      }
    },
    country() {
      if (this.selectedCountry) {
        return this.selectedCountry;
      } else {
        if (this.level && this.level.data) {
          return this.getCountry(this.level.data.country_id);
        }
        return null;
      }
    },
    inheritedTimezoneId() {
      if (
        this.level &&
        this.level.data &&
        this.level.data.inherited_timezone_id
      ) {
        return this.level.data.inherited_timezone_id;
      }
      return null;
    },
    timezoneUpdateAllowed() {
      if (this.level && this.level.data && this.level.data.timezone_allowed) {
        return this.level.data.timezone_allowed;
      }
      return null;
    },
    mapId() {
      if (this.unsetMapValue) {
        return null;
      } else {
        return (
          (this.selectedMap && this.selectedMap.id) ||
          (this.selectedLevel &&
            this.selectedLevel.length &&
            this.selectedLevel[0].map_id)
        );
      }
    },
    displayLevelName() {
      const { level } = this;
      const locale = getLang();
      if (
        level &&
        level.data &&
        level.data.translations &&
        level.data.translations.length
      ) {
        const filtered = level.data.translations.filter(
          item => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return level.data.name;
        }
      } else {
        return "";
      }
    },
    displayLevelSavedName() {
      const { levelTranslations } = this;
      const locale = getLang();
      if (levelTranslations && levelTranslations.length) {
        const filtered = levelTranslations.filter(
          item => item.locale === locale
        );
        if (filtered && filtered.length) {
          return filtered[0].attributes.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
    hasTranslationsClause() {
      const { level, languages, levelTranslations } = this;
      if (languages && languages.data && languages.data.length) {
        return (
          languages.data.length === levelTranslations.length ||
          (level &&
            level.data &&
            level.data.translations &&
            level.data.translations.length === languages.data.length)
        );
      }
      return false;
    },
    workHoursFrom() {
      if (this.workingHoursFrom) {
        return this.workingHoursFrom;
      }
      if (this.level && this.level.data && this.level.data.working_hours_from) {
        return this.level.data.working_hours_from;
      }
      return null;
    },
    workHoursTo() {
      if (this.workingHoursTo) {
        return this.workingHoursTo;
      }
      if (this.level && this.level.data && this.level.data.working_hours_to) {
        return this.level.data.working_hours_to;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("level", [
      "getLevels",
      "getSublevels",
      "setSelectedLevel",
      "selectMap",
      "getMaps",
      "unsetMap"
    ]),
    ...mapActions("country", ["setSelectedCountry"]),
    ...mapActions("timezone", ["setSelectedTimezone"]),
    submitForm(values, { resetForm }) {
      if (values) {
        const data = {
          ...this.selectedLevel[0],
          ...values
        };

        this.submitting = true;
        // this.setMode("display");
        this.setMode("edit");
        this.submitCompanyData(data, resetForm);
      }
    },
    submitCompanyData(values, resetForm) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      if (this.levelTranslations.length) {
        values.translations = this.levelTranslations;
      } else if (
        this.level.data.translations &&
        this.level.data.translations.length
      ) {
        values.translations = this.level.data.translations;
      }

      values.country_id = this.country ? this.country.id : null;
      values.timezone_id = this.timezone ? this.timezone.id : null;

      if (this.mapId) values.map_id = this.mapId;
      else values.map_id = null;

      if (this.workHoursFrom) values.working_hours_from = this.workHoursFrom;
      if (this.workHoursTo) values.working_hours_to = this.workHoursTo;

      httpServiceAuth
        .put(
          `${apiEndpoints.company.levels}/${this.selectedLevel[0].id}`,
          values
        )
        .then(() => {
          this.unsetMap(false);
          resetForm();
          this.$store.dispatch("timezone/setUnsetTimezoneStatus", false, {
            root: true
          });
          if (this.selectedLevel[0].level_id) {
            this.getSublevels(this.selectedLevel[0].level_id);
          } else {
            this.getLevels();
          }
          // this.getLevels();

          // }
          this.setSelectedCountry(null);
          this.setSelectedLevel([
            {
              ...values
            }
          ]);
          this.$store.commit("level/setLevelTranslations", [], {
            root: true
          });
          this.$router.push({
            name: "r_edit-level",
            params: this.$route.params
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      this.$refs.submitFormBtn.click();
    },
    getCurrentLevel(levelId) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.$store.commit("level/setLevelTranslations", [], { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.levels}/${levelId}`)
        .then(response => {
          this.level = response.data;
          this.$store.commit("level/setQrCode", response.data.data.qrcode, {
            root: true
          });
          this.$nextTick(() => {
            this.setTranslationFields();
          });
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    setMode(mode) {
      this.mode = mode;
    },
    displayMapName(id) {
      if (this.maps && this.maps.data && this.maps.data.length) {
        const filtered = this.maps.data.filter(item => item.id === id);
        if (filtered && filtered.length) {
          return filtered[0].name;
        }
        return "";
      }
      return "";
    },
    setTranslationFields() {
      const currentLang = getLang();
      function sortFn(a, b) {
        if (a.locale === currentLang && b.locale !== currentLang) {
          return -1;
        }

        if (a.locale !== currentLang && b.locale === currentLang) {
          return 1;
        }

        return 0;
      }

      const { languages, level, levelTranslations } = this;
      // edit
      if (
        languages &&
        languages.data &&
        languages.data.length &&
        level &&
        level.data &&
        level.data.translations
      ) {
        let sortedLanguages = languages.data.sort(sortFn);
        sortedLanguages = sortedLanguages.map((item, idx) => {
          const defaultObj = {
            locale: item.locale,
            attributes: {
              name: ""
            }
          };
          if (levelTranslations && levelTranslations.length) {
            return {
              ...levelTranslations[idx],
              attributes: {
                ...levelTranslations[idx].attributes
              }
            };
          } else if (level.data.translations.length) {
            const sortedSavedTranslations = level.data.translations.sort(
              sortFn
            );
            const savedTr = sortedSavedTranslations.find(
              tr => tr.locale === item.locale
            );
            if (savedTr) {
              return {
                ...savedTr,
                attributes: {
                  ...savedTr.attributes
                }
              };
            } else {
              return defaultObj;
            }
          } else {
            return defaultObj;
          }
        });
        this.$store.commit("level/setLevelTranslations", sortedLanguages, {
          root: true
        });
      }
    },
    displayTime(time) {
      if (!time) {
        return;
      }
      // Luxon fix for am/pm... remove when globalDateFormat is Luxon oriented
      let timeFormat = this.globalTimeFormat;
      if (timeFormat.slice(-1) === "A") {
        timeFormat = timeFormat.replace(/.$/, "a");
      }
      return DateTime.fromISO(time).toFormat(timeFormat || "HH:mm");
    }
  },
  beforeUnmount() {
    this.setSelectedLevel(null);
    this.unsetMap(false);
    this.$store.commit("level/setWorkingHoursFrom", null, { root: true });
    this.$store.commit("level/setWorkingHoursTo", null, { root: true });
    this.$store.commit("level/setLevelTranslations", [], {
      root: true
    });
    this.$store.commit("level/setQrCode", null, {
      root: true
    });
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  mixins: [timezonesMixin, countriesMixin, helpOnlineMixin]
};
</script>
